import Link from "next/link";
import React from "react";

import {
    languageNames,
    localeCountryCodeMap,
    localeCountryNameMap,
} from "../../translations/config";
import { Locale } from "../../types/locale";
import { hostForLocale } from "../../utils/locale";
import Grid from "../layout/grid";
import styles from "./countryCards.module.scss";

export const CountryCards: React.FC = () => {
    const localeCountryNames = { ...localeCountryNameMap };
    delete localeCountryNames.global;
    delete localeCountryNames["fr-FR"];
    delete localeCountryNames["es-ES"];
    delete localeCountryNames["it-IT"];
    const countryList = Object.entries(localeCountryNames).map(([locale, countryName]) => {
        const path = hostForLocale(locale as Locale);
        return (
            <Link
                key={locale}
                href={`${path}/${locale}`}
                prefetch={false}
                className={styles.localeLink}
                data-testid={`country-select-${locale}`}
            >
                <img
                    src={`/images/flags/${localeCountryCodeMap[locale]}.svg`}
                    alt={`${locale} flag`}
                />
                <p className={styles.countryName}>
                    {countryName === "Canada"
                        ? `${countryName} - ${languageNames[locale]}`
                        : countryName}
                </p>
            </Link>
        );
    });

    return (
        <div className={styles.countryCards}>
            <h3 className={styles.heading}>Afterpay International</h3>
            <p className={styles.subheading}>Choose your region & language below.</p>
            <Grid fullWidth>{countryList}</Grid>
        </div>
    );
};
