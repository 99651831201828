import classNames from "classnames/bind";
import { useRouter } from "next/router";
import React, { ReactNode } from "react";

import { useIsArcade } from "@/hooks/useIsArcade";

import { Locale } from "../types/locale";
import { PageMeta } from "../types/page";
import styles from "./layout.module.scss";
import SharedHead from "./shared-head";

const cx = classNames.bind(styles);

type OwnProps = {
    children: ReactNode;
    useOldStyles?: boolean;
    preventIndexing?: boolean;
};

type Props = OwnProps & PageMeta;

const BareLayout: React.FC<Props> = (props: Props) => {
    const { children, useOldStyles = false, preventIndexing = false } = props;

    const router = useRouter();
    const currentLocale = router.locale as Locale;
    const isArcade = useIsArcade();
    const arcadeMigrationWrapperClassName = cx({
        [styles.arcade]: isArcade,
        [styles.bareLayout]: true,
    });

    return (
        <>
            <SharedHead
                currentLocale={currentLocale}
                description="Afterpay is fully integrated with all your favorite stores. Shop as usual, then choose Afterpay as your payment method at checkout. First-time customers complete a quick registration, returning customers simply log in."
                title="Buy now pay later with Afterpay"
                useOldStyles={useOldStyles}
                preventIndexing={preventIndexing}
            />
            <div className={arcadeMigrationWrapperClassName}>
                <main>{children}</main>
            </div>
        </>
    );
};

export default BareLayout;
