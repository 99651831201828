import classNames from "classnames";
import Cookies from "js-cookie";
import { usePathname } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";

import { Locale } from "@/types/locale";

type Props = {
    currentLocale: Locale;
    currentPath: string;
};

const iosAppIdMap = {
    "en-US": "1401019110",
    "en-CA": "1401019110",
    "en-AU": "1230286588",
    "en-NZ": "1230286588",
    "en-GB": "1474022186",
    "fr-CA": "1401019110",
};

const iosUrlMap = {
    "en-GB": "https://apps.apple.com/gb/app/clearpay-buy-now-pay-later/id1474022186",
    "en-US": "https://apps.apple.com/us/app/afterpay-buy-now-pay-later/id1401019110",
    "en-CA": "https://apps.apple.com/ca/app/afterpay-buy-now-pay-later/id1401019110",
    "fr-CA": "https://apps.apple.com/ca/app/afterpay-buy-now-pay-later/id1401019110",
    "en-AU": "https://apps.apple.com/au/app/afterpay-shop-smarter/id1230286588",
    "en-NZ": "https://apps.apple.com/nz/app/afterpay-shop-smarter/id1230286588",
};

const androidUrlMap = {
    "en-GB": "https://play.google.com/store/apps/details?id=com.afterpaymobile.uk",
    "en-AU": "https://play.google.com/store/apps/details?id=com.afterpaymobile",
    "en-NZ": "https://play.google.com/store/apps/details?id=com.afterpaymobile",
    "en-US": "https://play.google.com/store/apps/details?id=com.afterpaymobile.us",
    "en-CA": "https://play.google.com/store/apps/details?id=com.afterpaymobile.us",
    "fr-CA": "https://play.google.com/store/apps/details?id=com.afterpaymobile.us",
};

export const useCheckSmartBannerDisabled = () => {
    const { smartBannerDisabledPath } = useFeatureFlags();
    const rules: string[] = useMemo(() => smartBannerDisabledPath ?? [], [smartBannerDisabledPath]);

    // default block country select page
    rules.push("/country-select");

    const check = useCallback(
        (path: string) => {
            return rules?.some((rule) => {
                if (typeof rule === "string") {
                    if (rule.includes("*")) {
                        const regex = new RegExp(rule.replaceAll("*", ".*"));
                        return regex.test(path);
                    } else {
                        return path === rule;
                    }
                }
            });
        },
        [rules]
    );
    return check;
};

export const useIsSmartBannerDisabled = () => {
    const path = usePathname();
    const checkSmartBannerDisabled = useCheckSmartBannerDisabled();
    return checkSmartBannerDisabled(path);
};

export const IosSmartBanner: React.FC<Props> = ({ currentLocale, currentPath }) => {
    const iosAppId = iosAppIdMap[currentLocale] ?? iosAppIdMap["en-AU"];
    const checkSmartBannerDisabled = useCheckSmartBannerDisabled();
    if (checkSmartBannerDisabled(currentPath)) {
        return null;
    }
    return (
        <>
            <meta name="apple-itunes-app" content={`app-id=${iosAppId}`} />
        </>
    );
};

const androidSmartBannerCookieKey = "androidSmartBannerClosed";
const COOKIE_DURATION_DAYS = 10;
const androidSmartBannerCookieDisableValue = "1";

const localAfterpayIconPath = "/images/app-icon.png";
const localArcadeIconPath = "/images/app-icon.arcade.png";

export const AndroidSmartBanner: React.FC<Props> = ({ currentLocale, currentPath }) => {
    const isArcade = currentLocale === "en-US";
    const title = currentLocale === "en-GB" ? "Clearpay" : "Afterpay";
    const logoPath = isArcade ? localArcadeIconPath : localAfterpayIconPath;

    const iosUrl = iosUrlMap[currentLocale] ?? iosUrlMap["en-AU"];
    const androidUrl = androidUrlMap[currentLocale] ?? androidUrlMap["en-AU"];

    const [targetUrl, setTargetUrl] = useState(undefined);
    const [isClosed, setIsClosed] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (targetUrl !== undefined) {
            return;
        }
        if (window) {
            const userAgent = window.navigator?.userAgent ?? "";
            if (userAgent.includes("Android")) {
                setTargetUrl(androidUrl);
            } else if (userAgent.includes("iPhone")) {
                setTargetUrl(iosUrl);
            } else {
                setIsClosed(true);
            }

            if (Cookies.get(androidSmartBannerCookieKey) === androidSmartBannerCookieDisableValue) {
                setIsClosed(true);
            }
        }
    }, [androidUrl, iosUrl, targetUrl]);

    const handleClose = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setIsClosed(true);
        Cookies.set(androidSmartBannerCookieKey, androidSmartBannerCookieDisableValue, {
            expires: COOKIE_DURATION_DAYS,
        });
    }, []);

    const checkSmartBannerDisabled = useCheckSmartBannerDisabled();
    const smartBannerDisabled = checkSmartBannerDisabled(currentPath);

    useEffect(() => {
        if (smartBannerDisabled) {
            setIsClosed(true);
        }
    }, [smartBannerDisabled]);

    const handleGo = useCallback(
        (e: React.MouseEvent) => {
            window.open(targetUrl, "_blank", "noopener,noreferrer");
            handleClose(e);
        },
        [targetUrl, handleClose]
    );

    if (isClosed) {
        return null;
    }

    return (
        <div
            className={classNames(
                "android-smart-banner fixed bottom-0 left-0 w-full bg-white py-2 px-4 justify-center items-center z-999 h-11 overflow-hidden hidden sm:flex select-none gap-1 shadow-md border-t-0-1 border-gray-9",
                {
                    "arcade font-cashsans": isArcade,
                }
            )}
            onClick={handleGo}
        >
            <div className="flex-1 flex gap-2 items-center">
                <img
                    src={logoPath}
                    className={classNames(
                        "h-8 rounded-lg aspect-square",
                        "border-0-1 border-opacity-10 border-black"
                    )}
                />
                <div className="flex flex-col justify-center">
                    <span
                        className={classNames("text-md text-gray20 font-bold", {
                            "font-cashsans": isArcade,
                        })}
                    >
                        {title}
                    </span>
                    <span
                        className={classNames("text-[11px] mt-[-3px] text-gray50", {
                            "font-cashsans": isArcade,
                        })}
                    >
                        Open in the {title} app
                    </span>
                </div>
            </div>

            <a
                href={targetUrl}
                target="_blank"
                rel="noreferrer"
                className={classNames(
                    "px-3 py-1 rounded-xl  h-6 space tracking-wide text-xxs bg-mint text-black",
                    {
                        "!bg-black !text-white rounded-2xl font-cashsans": isArcade,
                    }
                )}
            >
                OPEN
            </a>
        </div>
    );
};
